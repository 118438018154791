<template>
  <v-container v-if="gameList">
    <v-row>
      <v-col>
        <v-list dense>
          <v-list-item v-for="state in gameList" :key="state.id">
            <v-list-item-avatar>
              <v-icon v-if="state.dead">fas fa-skull-crossbones</v-icon>
              <v-icon v-else-if="state.mute">fas fa-volume-mute</v-icon>
              <v-img v-else :src="state.user.picture"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title :class="state.dead ? '' : 'success--text'">
                {{ state.role.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-icon class="ml-1" color="pink lighten-4" small v-if="couple[state.id]">fas fa-heart</v-icon>
                <v-icon class="ml-1" color="indigo" small v-if="werewolf[state.id]">fas fa-paw-claws</v-icon>
                <v-icon class="ml-1" color="green lighten-2" small v-if="pray[state.id]">fas fa-praying-hands</v-icon>
                <v-icon class="ml-1" color="red darken-4" small v-if="vampire[state.id]">fas fa-bat</v-icon>
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="state.user">{{ state.user.name }}</v-list-item-subtitle>
              <v-list-item-subtitle v-else class="error--text">Leaved</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon small @click="openCommand(state.id)">
                <v-icon small>fas fa-terminal</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea v-model="note" outlined label="Note" auto-grow></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-dialog v-model="stopDialog" persistent max-width="290">
          <template v-slot:activator="{ on }">
            <v-btn rounded outlined block color="error" v-on="on">Stop</v-btn>
          </template>
          <v-card>
            <v-card-title class="headline">Stop game?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="stopDialog = false">Cancel</v-btn>
              <v-btn color="error" outlined @click="stopGame">Confirm</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-dialog v-model="commandDialog">
      <v-sheet class="pa-2">
        <v-row>
          <v-col>
            <v-list>
              <v-list-item v-if="selectedState.user">
                <v-list-item-avatar>
                  <v-img :src="selectedState.user.picture"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ selectedState.user.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-else>
                <v-list-item-content>
                  <v-list-item-title>
                    Leaved
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="selectedState.role">
                <v-list-item-avatar>
                  <v-icon v-text="selectedState.role.icon"></v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" block outlined>
                        {{ selectedState.role.name }}
                      </v-btn>
                    </template>
                    <v-list max-height="40vh" style="overflow-y: scroll;">
                      <v-list-item v-for="role in roleArray" :key="role.id" @click="changeRole(selectedState.id, role.id)">
                        <v-list-item-title>{{ role.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row v-if="selectedState.role">
          <v-col>
            <p v-text="selectedState.role.effect1"></p>
            <p v-text="selectedState.role.effect2"></p>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <v-btn @click="deadCommand(selectedState.id, !selectedState.dead)" block :outlined="!selectedState.dead" :color="selectedState.dead ? 'error' : 'grey'"><v-icon left>fas fa-skull-crossbones</v-icon> Dead</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn @click="muteCommand(selectedState.id, !selectedState.mute)" block :outlined="!selectedState.mute" :color="selectedState.mute ? 'error' : 'grey'">Mute <v-icon right>fas fa-volume-mute</v-icon></v-btn>
          </v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row dense>
          <v-col cols="6">
            <v-btn small @click="coupleNote(selectedState.id)" block :outlined="!couple[selectedState.id]" :color="couple[selectedState.id] ? 'pink lighten-4' : 'grey'"><v-icon left>fas fa-heart</v-icon> Couple</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn small @click="werewolfNote(selectedState.id)" block :outlined="!werewolf[selectedState.id]" :color="werewolf[selectedState.id] ? 'indigo' : 'grey'"><v-icon left>fas fa-paw-claws</v-icon> Wolf</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn small @click="prayNote(selectedState.id)" block :outlined="!pray[selectedState.id]" :color="pray[selectedState.id] ? 'green lighten-2' : 'grey'"><v-icon left>fas fa-praying-hands</v-icon> Pray</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn small @click="vampireNote(selectedState.id)" block :outlined="!vampire[selectedState.id]" :color="vampire[selectedState.id] ? 'red darken-4' : 'grey'"><v-icon left>fas fa-bat</v-icon> Vampire</v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </v-dialog>
  </v-container>
  <v-container v-else>
    <v-row>
      <v-col>
        <v-dialog>
          <template v-slot:activator="{ on }">
            <v-btn color="secondary" block v-on="on">Room Info</v-btn>
          </template>
          <v-sheet class="text-center pa-5">
            <h4>Room ID</h4>
            <qrcode :value="roomId" error-correction-level="M" :quality="1" :scale="6"></qrcode>
            <h1 class="mb-3">{{ roomId }}</h1>
            <v-dialog v-model="destroyDialog" persistent max-width="290">
              <template v-slot:activator="{ on }">
                <v-btn color="error" block small v-on="on">Destroy Room</v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">Destroy room?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="success" text @click="destroyDialog = false">Cancel</v-btn>
                  <v-btn color="error" outlined @click="destroyRoom">Confirm</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-sheet>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Role
              <template v-slot:actions>
                <v-badge inline :content="roleCount.toString()" color="error"></v-badge>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0">
              <h3 :class="'text-center ' + (point > 0 ? 'success--text' : (point < 0 ? 'error--text' : ''))">Point: {{ point }}</h3>
              <v-list two-line dense>
                <v-list-item v-for="role in roleArray" :key="role.id" class="px-0">
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-list-item-icon v-on="on">
                        <v-icon v-text="role.icon">fas fa-question</v-icon>
                      </v-list-item-icon>
                    </template>
                    <p v-text="role.effect1"></p>
                    <p v-text="role.effect2"></p>
                  </v-tooltip>
                  <v-list-item-content>
                    <v-list-item-title>{{role.name}} ({{role.point > 0 ? '+' : ''}}{{role.point}})</v-list-item-title>
                    <v-list-item-subtitle v-text="role.team"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-toolbar flat dense>
                      <v-btn icon x-small @click="roleMinus(role.id)" :disabled="starting">
                        <v-icon small>fas fa-minus</v-icon>
                      </v-btn>
                      <v-btn small icon>{{ roleList[role.id] || 0 }}</v-btn>
                      <v-btn icon x-small @click="rolePlus(role.id)" :disabled="starting">
                        <v-icon small>fas fa-plus</v-icon>
                      </v-btn>
                    </v-toolbar>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Player
              <template v-slot:actions>
                <v-badge inline :content="playerCount.toString()" color="error"></v-badge>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list dense>
                <v-list-item v-if="playerArray.length <= 0">
                  <v-list-item-content>
                    <v-list-item-title>No Player</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-for="player in playerArray" :key="player.id" class="px-0">
                  <v-list-item-avatar>
                    <v-img :src="player.picture"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ player.name }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click="ignorePlayer(player.id)" :disabled="starting">
                      <v-icon :color="ignoreList[player.id] ? 'error' : 'grey lighten-1'">fas fa-ban</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn rounded :disabled="!ready" outlined block color="primary" @click="startGame" :loading="starting">Start</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueQrcode from 'vue-qrcode'
import { database } from '../firebase.js'
import debounce from 'lodash.debounce'

export default {
  name: 'moderator',
  components: {
    'qrcode': VueQrcode
  },
  props: ['roomId', 'userId'],
  data () {
    return {
      destroyDialog: false,
      commandDialog: false,
      stopDialog: false,
      roles: null,
      roleList: {},
      roomRef: database.ref('Room').child(this.roomId),
      roomData: null,
      starting: false,
      selectedStateId: null,
      couple: {},
      werewolf: {},
      pray: {},
      vampire: {},
      note: ''
    }
  },
  watch: {
    roleList (val) {
      this.roomRef.child('Role').set(val)
    },
    couple (val) {
      this.roomRef.child('Note').child('couple').set(val)
    },
    werewolf (val) {
      this.roomRef.child('Note').child('werewolf').set(val)
    },
    pray (val) {
      this.roomRef.child('Note').child('pray').set(val)
    },
    vampire (val) {
      this.roomRef.child('Note').child('vampire').set(val)
    },
    note: debounce(function (val) {
      this.roomRef.child('Note').child('note').set(val)
    }, 1500)
  },
  computed: {
    gameList () {
      if (!this.roomData) return null
      if (!this.roomData.State) return null
      const output = []
      for (const key in this.roomData.State) {
        const state = this.roomData.State[key]
        output.push({
          id: key,
          user: this.players[key],
          role: this.roles[state.roleId],
          dead: !!state.dead,
          mute: !!state.mute
        })
      }
      return output.sort((a, b) => a.role.priority - b.role.priority).sort((a, b) => a.dead - b.dead)
    },
    selectedState () {
      if (!this.selectedStateId) return {}
      if (!this.roomData) return {}
      if (!this.roomData.State) return {}
      const state = this.roomData.State[this.selectedStateId]
      return {
        id: this.selectedStateId,
        user: this.players[this.selectedStateId],
        role: this.roles[state.roleId],
        dead: !!state.dead,
        mute: !!state.mute
      }
    },
    players () {
      if (!this.roomData) return {}
      return this.roomData.Player
    },
    playerArray () {
      const players = this.players
      const output = []
      for (const key in players) {
        const player = players[key]
        player.id = key
        output.push(player)
      }
      return output
    },
    ignoreList () {
      if (!this.roomData) return {}
      const players = this.roomData.Ignore
      if (!players) return {}
      const output = {}
      for (const key in players) {
        if (players[key]) output[key] = true
      }
      return output
    },
    roleArray () {
      const output = []
      for (const key in this.roles) {
        const role = this.roles[key]
        role.id = key
        output.push(role)
      }
      return output.sort((a, b) => a.priority - b.priority)
    },
    point () {
      let sum = 0
      for (const key in this.roleList) {
        const count = this.roleList[key]
        const role = this.roles[key]
        sum += (count * role.point)
      }
      return sum
    },
    roleCount () {
      let sum = 0
      for (const key in this.roleList) sum += this.roleList[key]
      return sum
    },
    playerCount () {
      let sum = 0
      for (const key in this.playerArray) {
        if (!this.ignoreList[this.playerArray[key].id]) sum += 1
      }
      return sum
    },
    ready () {
      if (this.playerCount < 1) return false
      if (this.roleCount !== this.playerCount) return false
      return true
    }
  },
  mounted () {
    this.roomRef = database.ref('Room').child(this.roomId)
    database.ref('Role').once('value').then((snapshot) => {
      this.roles = snapshot.val()
    }).catch((error) => {
      console.error('get role', error)
    })
    this.roomRef.on('value', (snapshot) => {
      this.roomData = snapshot.val()
      this.roleList = this.roomData.Role || {}
      if (this.roomData.Note) {
        this.couple = this.roomData.Note.couple || {}
        this.werewolf = this.roomData.Note.werewolf || {}
        this.pray = this.roomData.Note.pray || {}
        this.vampire = this.roomData.Note.vampire || {}
        this.note = this.roomData.Note.note || ''
      }
    }, (error) => {
      this.roomData = null
      console.error('get room', error.message)
      this.destroyRoom()
    })
  },
  destroyed () {
    this.roomRef.off('value')
  },
  methods: {
    destroyRoom () {
      this.roomRef.off('value')
      this.roomRef.remove().catch(() => {})
      database.ref('User').child(this.userId).remove().catch(() => {})
    },
    rolePlus (roleId) {
      const data = this.roleList
      this.roleList = {}
      if (data[roleId]) data[roleId] += 1
      else data[roleId] = 1
      this.roleList = data
    },
    roleMinus (roleId) {
      const data = this.roleList
      this.roleList = {}
      if (data[roleId] > 0) data[roleId] -= 1
      else data[roleId] = 0
      if (data[roleId] === 0) delete data[roleId]
      this.roleList = data
    },
    ignorePlayer (playerId) {
      if (this.ignoreList[playerId]) this.roomRef.child('Ignore').child(playerId).remove()
      else this.roomRef.child('Ignore').child(playerId).set(true)
    },
    startGame () {
      console.log('Start!')
      this.starting = true
      let roleList = []
      for (const key in this.roleList) {
        for (let i = 0; i < this.roleList[key]; i++) {
          roleList.push(key)
          roleList = this.shuffle(roleList)
        }
        roleList = this.shuffle(roleList)
      }
      const state = {}
      for (const key in this.playerArray) {
        if (!this.ignoreList[this.playerArray[key].id]) {
          state[this.playerArray[key].id] = {
            roleId: roleList.length % 2 === 0 ? roleList.pop() : roleList.shift()
          }
        }
      }
      this.roomRef.child('State').set(state)
      this.starting = false
    },
    stopGame () {
      this.roomRef.child('State').remove()
      this.roomRef.child('Note').set({ note: '' })
    },
    openCommand (stateId) {
      this.selectedStateId = stateId
      this.commandDialog = true
    },
    deadCommand (stateId, val) {
      this.roomRef.child('State').child(stateId).child('dead').set(val)
    },
    muteCommand (stateId, val) {
      this.roomRef.child('State').child(stateId).child('mute').set(val)
    },
    changeRole (stateId, roleId) {
      this.roomRef.child('State').child(stateId).child('roleId').set(roleId)
    },
    coupleNote (stateId) {
      const data = this.couple
      this.couple = {}
      if (data[stateId]) delete data[stateId]
      else data[stateId] = true
      this.couple = data
    },
    werewolfNote (stateId) {
      const data = this.werewolf
      this.werewolf = {}
      if (data[stateId]) delete data[stateId]
      else data[stateId] = true
      this.werewolf = data
    },
    prayNote (stateId) {
      const data = this.pray
      this.pray = {}
      if (data[stateId]) delete data[stateId]
      else data[stateId] = true
      this.pray = data
    },
    vampireNote (stateId) {
      const data = this.vampire
      this.vampire = {}
      if (data[stateId]) delete data[stateId]
      else data[stateId] = true
      this.vampire = data
    },
    shuffle (array) {
      let currentIndex = array.length
      let temporaryValue
      let randomIndex
      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1
        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
      }
      return array
    }
  }
}
</script>

<style>
.v-expansion-panel-content__wrap {
  padding: 0px 12px;
}
</style>

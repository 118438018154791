<template>
  <v-container v-if="isCreator === null">
    <v-row>
      <v-col class="text-center">
        <h2>IN YOUR POSITION!</h2>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else-if="!roomId">
    <v-row>
      <v-col class="text-center">
        <h1>Werewolf</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn block color="primary" @click="createRoom" :loading="creating">Create Room</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn block color="success" @click.stop="joinRoom">Join Room</v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-10" dense>
      <v-col cols="12">
        <v-list-item>
          <v-list-item-avatar>
            <v-img :src="user.picture"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="user.name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12">
        <v-dialog v-model="refreshDialog" persistent max-width="290">
          <template v-slot:activator="{ on }">
            <v-btn small block v-on="on">Refresh User</v-btn>
          </template>
          <v-card>
            <v-card-title class="headline">Refresh user?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="refreshDialog = false">Cancel</v-btn>
              <v-btn color="error" outlined @click="signOut">Confirm</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <p class="caption text-center mt-3">V1.1</p>
      </v-col>
    </v-row>
    <v-dialog v-model="joinDialog">
      <v-card>
        <v-card-title>
          <span class="headline">Enter Room ID</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <input id="roomInput" type="text" v-model="joinId" maxlength="6" @keypress.enter="enterPrompt" autocomplete="off">
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="joinDialog = false">Cancel</v-btn>
          <v-btn color="success" @click="enterPrompt">Join</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <moderator v-else-if="isCreator" :room-id="roomId" :user-id="userId"></moderator>
  <player v-else :room-id="roomId" :user-id="userId"></player>
</template>

<script>
import { database, auth } from '../firebase.js'
import Moderator from '../components/Moderator.vue'
import Player from '../components/Player.vue'
import liff from '@line/liff'

export default {
  name: 'home',
  props: ['userId'],
  components: {
    Moderator,
    Player
  },
  data () {
    return {
      refreshDialog: false,
      creating: false,
      dbRef: database.ref('User').child(this.userId),
      roomId: null,
      isCreator: null,
      joinDialog: false,
      joinId: '',
      user: {
        name: auth.currentUser.displayName,
        picture: auth.currentUser.photoURL
      }
    }
  },
  watch: {
    roomId (val) {
      if (val) {
        database.ref('Room').child(val).child('Creator').once('value').then((snapshot) => {
          if (snapshot.val() === this.userId) this.isCreator = true
          else this.isCreator = false
        }).catch(() => {
          this.isCreator = false
        })
      } else {
        this.isCreator = false
      }
    }
  },
  mounted () {
    this.dbRef.on('value', (snapshot) => {
      this.roomId = snapshot.val()
      this.isCreator = this.roomId === null ? false : null
    }, (error) => {
      console.error('User DB', error)
    })
  },
  destroyed () {
    this.dbRef.off('value')
  },
  methods: {
    signOut () {
      this.refreshDialog = false
      auth.signOut()
    },
    genRoomId () {
      let rand = ''
      // while (rand.length !== 6) rand = Math.random().toString(36).substring(7).toUpperCase()
      const characters = 'ACDEFGHJKLMNPQRTUVWXY3469'
      for (let i = 0; i < 6; i++) {
        rand += characters.charAt(Math.floor(Math.random() * characters.length))
      }
      return rand
    },
    createRoom () {
      this.creating = true
      const roomId = this.genRoomId()
      console.log('Create', roomId)
      database.ref('Room').child(roomId).child('Creator').set(this.userId).then(() => {
        this.enterRoomId(roomId)
      }).catch((error) => {
        alert(`Bad luck! Try again! (${error.message})`)
      }).finally(() => {
        this.creating = false
      })
    },
    joinRoom () {
      if (liff.getOS() === 'android') {
        liff.scanCode().then((value) => {
          const roomId = value.value || ''
          this.enterRoomId(roomId)
        }).catch((error) => {
          console.error('scan code', error.message)
          this.joinPrompt()
        })
      } else {
        this.joinPrompt()
      }
    },
    joinPrompt () {
      this.joinDialog = true
      this.joinId = ''
    },
    enterPrompt () {
      this.joinDialog = false
      this.enterRoomId(this.joinId.trim().toUpperCase())
    },
    enterRoomId (roomId) {
      if (roomId.length === 6) {
        database.ref('User').child(this.userId).set(roomId).catch((error) => {
          console.error('set room', error)
        })
      }
    }
  }
}
</script>

<style>
#roomInput {
  width: 100%;
  font-size: 2em;
  text-align: center;
  color: #000;
  border: 1px #000 solid;
  border-radius: 6px;
  padding: 12px;
  text-transform: uppercase;
}
</style>

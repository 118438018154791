<template>
  <v-container v-if="!state">
    <v-row>
      <v-col>
        <v-dialog>
          <template v-slot:activator="{ on }">
            <v-btn color="secondary" block v-on="on">Room Info</v-btn>
          </template>
          <v-sheet class="text-center pa-5">
            <h4>Room ID</h4>
            <qrcode :value="roomId" error-correction-level="M" :quality="1" :scale="6"></qrcode>
            <h1 class="mb-3">{{ roomId }}</h1>
            <v-dialog v-model="leaveDialog" persistent max-width="290">
              <template v-slot:activator="{ on }">
                <v-btn color="error" block small v-on="on">Leave Room</v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">Leave room?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="success" text @click="leaveDialog = false">Cancel</v-btn>
                  <v-btn color="error" outlined @click="leaveCommand">Confirm</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-sheet>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <v-icon class="my-10" size="100" color="black">fas fa-hourglass-half</v-icon>
        <h2>Waiting Moderator</h2>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row v-if="!state.dead">
      <v-col class="text-center">
        <p class="error--text ma-0">When you reveal this display, you die!</p>
      </v-col>
    </v-row>
    <v-row v-if="!!state.mute && !state.dead">
      <v-col class="text-center">
        <v-icon size="100" color="error">fas fa-volume-mute</v-icon>
        <h1 class="error--text">Silent</h1>
        <h3 class="ma-0">Have a Nice Day!</h3>
      </v-col>
    </v-row>
    <v-row v-if="!state.dead">
      <v-col>
        <v-btn block outlined @click="isHide = !isHide">{{ isHide ? 'Show' : 'Hide' }} Role</v-btn>
      </v-col>
    </v-row>
    <template v-if="roleData">
      <v-card v-if="!isHide || !!state.dead" class="pa-3">
        <v-row>
          <v-col class="text-center" cols="12">
            <h1 :class="!!state.dead ? 'error--text' : ''">{{ roleData.name }}</h1>
            <v-icon class="my-10" size="100" :color="!!state.dead ? 'error' : 'black'" v-text="roleData.icon">fas fa-question</v-icon>
            <h1 v-if="!!state.dead" class="error--text">You dead</h1>
            <h3 v-if="!!state.dead" class="ma-0">Reveal your role.</h3>
          </v-col>
          <v-col cols="12" v-if="!state.dead">
            <p v-text="roleData.effect1"></p>
            <p v-text="roleData.effect2"></p>
          </v-col>
        </v-row>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import VueQrcode from 'vue-qrcode'
import { database, auth } from '../firebase.js'

export default {
  name: 'player',
  components: {
    'qrcode': VueQrcode
  },
  props: ['roomId', 'userId'],
  data () {
    return {
      leaveDialog: false,
      roleRef: database.ref('Room').child(this.roomId).child('State').child(this.userId),
      state: null,
      roleId: null,
      roleData: null,
      isHide: true
    }
  },
  watch: {
    roleId (newVal, oldVal) {
      if (newVal) {
        if (newVal !== oldVal) {
          database.ref('Role').child(newVal).once('value').then((snapshot) => {
            this.roleData = snapshot.val()
            this.isHide = false
          }).catch((error) => {
            console.error('ger role', error)
          })
        }
      } else {
        this.roleData = null
      }
    },
    state (state) {
      if (state) {
        this.roleId = state.roleId
      } else {
        this.roleId = null
      }
    }
  },
  mounted () {
    this.roleRef = database.ref('Room').child(this.roomId).child('State').child(this.userId)
    database.ref('Room').child(this.roomId).child('Player').child(this.userId).set({
      name: auth.currentUser.displayName,
      picture: auth.currentUser.photoURL
    }).then(() => {
      this.roleRef.on('value', (snapshot) => {
        this.state = snapshot.val()
      }, () => {
        this.state = null
        this.leaveCommand()
      })
    }).catch((error) => {
      console.log('set player', error.message)
      this.leaveCommand()
    })
  },
  destroyed () {
    this.roleRef.off('value')
  },
  methods: {
    leaveCommand () {
      this.roleRef.off('value')
      database.ref('Room').child(this.roomId).child('Player').child(this.userId).remove().catch(() => {})
      database.ref('User').child(this.userId).remove().catch(() => {})
    }
  }
}
</script>

<style>
</style>
